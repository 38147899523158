import { myTradesMutations } from './types'

export default {
  [myTradesMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [myTradesMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [myTradesMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [myTradesMutations.PUSH_LIST] (state, value) {
    state.list.push(...value)
  },

  [myTradesMutations.SET_FETCH_NEXT] (state, value) {
    state.fetchNext = value
  },

  [myTradesMutations.REMOVE_LIST_ITEM] (state, value) {
    const itemIndex = state.list.findIndex(item => item.id === value)
    if (itemIndex === -1) return

    state.list.splice(itemIndex, 1)
  },
}
