import { myTradesGetters } from './types'
import { LotFrontOffice } from 'Models/LotFrontOffice'
import { HorizonLot } from 'Models/HorizonLot'

export default {
  [myTradesGetters.IS_LOADING]: state => state.isLoading,
  [myTradesGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [myTradesGetters.LIST]: state => state.list.map(item => {
    return item.resourceType === 'create_lot_requests'
      ? new HorizonLot(item)
      : new LotFrontOffice(item)
  }),
  [myTradesGetters.FETCH_NEXT]: state => state.fetchNext,
}
