import {
  lotTypes,
  lotStatesI,
  reviewableRequestStates
} from '@shelf.network/js-sdk'

import { ModelBase } from './ModelBase'
import { formatLocation } from 'Utils/formatLocation'

import youtubeRegex from 'youtube-regex'

import get from 'lodash/get'
import round from 'lodash/round'
import isObject from 'lodash/isObject'

export class HorizonLot extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.id = String(raw.lotId)
    this.requestId = String(raw.id)
    this.hash = String(raw.hash)

    this.createdAt = this._date(raw.createdAt).getTime()
    this.startTime = this._date(raw.startTime).getTime()
    this.duration = this._num(raw.duration) * 1000
    this.currency = this._str(raw.currency)

    this.type = this._str(raw.lotTypeStr)
    this.typeI = this._num(raw.lotType)
    this.requestState = this._str(raw.requestState)
    this.platformId = this._str(raw.platformId)
    this.lotState = get(raw, 'lot.lotState', '')

    this.buyNowPrice = this._num(raw.buyNowPrice)
    this.startPrice = this._num(raw.startPrice)
    this.depositAmount = this._num(raw.deposit)

    this.minStep = this._num(raw.minStep)
    this.maxStep = this._num(raw.maxStep)

    const vehicle = get(raw, 'details') || {}

    this.isOnRoad = Boolean(vehicle.onRoad)
    this.isExchangeAllowed = Boolean(vehicle.priceNegotiable)

    this.city = this._str(vehicle.city)
    this.country = this._str(vehicle.country)
    this.location = formatLocation(this.country, this.city)

    this.name = this._str(vehicle.lotName)
    this.maker = this._str(vehicle.maker)
    this.model = this._str(vehicle.model)
    this.year = this._num(vehicle.year)

    this.images = vehicle.fullImgLinks || []
    this.hdImages = vehicle.hdImgLinks || []

    this.fuelType = this._str(vehicle.fuelType)
    this.transmission = this._str(vehicle.transmission)
    this.engineVolume = this._num(vehicle.engineVolume)
    this.engineVolumeCm = round(this.engineVolume * 1000, 1)

    this.vinCode = this._str(vehicle.vinCode)
    this.isRegistered = Boolean(vehicle.registered)
    this.color = this._str(vehicle.color)

    this.odometerValueKm = this._num(vehicle.odometerValue)
    this.odometerOriginValue = this._num(vehicle.odometerOriginValue)
    this.odometerOriginUnits = this._str(vehicle.odometerOriginUnits)

    this.wheel = this._str(vehicle.wheel)
    this.videoUrl = this._str(vehicle.youtubeLink)

    this.description = this._str(vehicle.notes)
    this._rawDescriptionLocalized = this._str(vehicle.notesLocalized)
  }

  get price () {
    return this.isDirectSale ? this.buyNowPrice : this.startPrice
  }

  get isDirectSale () {
    return this.typeI === lotTypes.sale
  }

  get isAuction () {
    return this.typeI === lotTypes.shelfAuction
  }

  get isFinished () {
    return this.lotState === lotStatesI.finished
  }

  get isRequestPending () {
    return this.requestState === reviewableRequestStates.pending
  }

  get isRequestApproved () {
    return this.requestState === reviewableRequestStates.approved
  }

  get isRequestRejected () {
    return this.requestState === reviewableRequestStates.rejected ||
      this.requestState === reviewableRequestStates.permanentlyRejected
  }

  get descriptionLocalized () {
    if (isObject(this._rawDescriptionLocalized)) {
      return this._rawDescriptionLocalized
    }

    try {
      return JSON.parse(this._rawDescriptionLocalized) || {}
    } catch (e) {
      return {}
    }
  }

  get youtubeLink () {
    if (!this.videoUrl) {
      return ''
    }

    const youtubeId = youtubeRegex().exec(this.videoUrl)[1]
    return youtubeId ? `https://www.youtube.com/embed/${youtubeId}` : ''
  }
}
