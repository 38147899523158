import {
  enums,
  lotStates,
  reviewableRequestStates
} from '@shelf.network/js-sdk'

import {
  myTradesActions,
  myTradesMutations,
  myTradesGetters
} from './types'
import { userGetters } from 'Store/entities/User/types'

import { MY_TRADES_PAGE_LIMIT } from '../constants'

import { sdk } from 'Services/shelfNetworkSdk'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

const loneSdkCall = new LoneSdkCall()

export default {
  async [myTradesActions.LOAD_LIST] (
    { commit, dispatch },
    { action, opts }
  ) {
    commit(myTradesMutations.SET_IS_LOADING, true)
    await dispatch(action, opts)
    commit(myTradesMutations.SET_IS_LOADING, false)
  },

  async [myTradesActions.LOAD_ACTIVE_LIST] ({ commit }, opts = {}) {
    const { data, fetchNext } = await loneSdkCall.takeLatest(
      sdk.frontOffice.getListings({
        query: {
          filter: {
            ...opts,
            state: enums.lotStates.pending
          },
          page: { limit: MY_TRADES_PAGE_LIMIT },
          include: 'vehicle'
        }
      })
    )

    commit(myTradesMutations.SET_LIST, data)
    commit(myTradesMutations.SET_FETCH_NEXT, fetchNext)
  },

  async [myTradesActions.LOAD_APPROVAL_LIST] (
    { commit, rootGetters },
    opts = {}
  ) {
    const { data, fetchNext } = await loneSdkCall.takeLatest(
      sdk.horizon.reviewableRequests.getPage({
        sort: '-created_at',
        state: reviewableRequestStates.pending,
        type: 'lots',
        reviewer: rootGetters[`entities/user/${userGetters.PLATFORMS}`]
          .join(','),
        'page[size]': MY_TRADES_PAGE_LIMIT
      })
    )

    commit(myTradesMutations.SET_LIST, data)
    commit(myTradesMutations.SET_FETCH_NEXT, fetchNext)
  },

  async [myTradesActions.LOAD_ARCHIVE_LIST] ({ commit }, opts = {}) {
    const { data, fetchNext } = await loneSdkCall.takeLatest(
      sdk.frontOffice.getListings({
        query: {
          filter: {
            ...opts,
            state: [
              enums.lotStates.finished,
              enums.lotStates.sold
            ].join(',')
          },
          page: { limit: MY_TRADES_PAGE_LIMIT },
          include: 'vehicle'
        }
      })
    )

    commit(myTradesMutations.SET_LIST, data)
    commit(myTradesMutations.SET_FETCH_NEXT, fetchNext)
  },

  async [myTradesActions.LOAD_MORE] ({ commit, getters }) {
    const fetchNext = getters[myTradesGetters.FETCH_NEXT]
    if (fetchNext === null) return

    commit(myTradesMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } = await fetchNext()
    commit(myTradesMutations.PUSH_LIST, data)
    commit(myTradesMutations.SET_FETCH_NEXT, newFetchNext)

    commit(myTradesMutations.SET_IS_NEXT_LOADING, false)
  },

  async [myTradesActions.CLOSE_LOT] ({ commit }, id) {
    await sdk.horizon.lots.close(id, lotStates.finished)
    commit(myTradesMutations.REMOVE_LIST_ITEM, id)
  },

  async [myTradesActions.APPROVE_REQUEST] ({ commit }, { id, hash }) {
    await sdk.horizon.reviewableRequests.manage(
      id,
      hash,
      reviewableRequestStates.approved
    )

    commit(myTradesMutations.REMOVE_LIST_ITEM, id)
  },

  async [myTradesActions.REJECT_REQUEST] ({ commit }, { id, hash }) {
    await sdk.horizon.reviewableRequests.manage(
      id,
      hash,
      reviewableRequestStates.rejected,
      'Rejected'
    )

    commit(myTradesMutations.REMOVE_LIST_ITEM, id)
  },

  [myTradesActions.SET_IS_LOADING] ({ commit }, value) {
    commit(myTradesMutations.SET_IS_LOADING, value)
  }
}
