export const myTradesActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_ACTIVE_LIST: 'LOAD_ACTIVE_LIST',
  LOAD_APPROVAL_LIST: 'LOAD_APPROVAL_LIST',
  LOAD_ARCHIVE_LIST: 'LOAD_ARCHIVE_LIST',
  LOAD_MORE: 'LOAD_MORE',
  CLOSE_LOT: 'CLOSE_LOT',
  APPROVE_REQUEST: 'APPROVE_REQUEST',
  REJECT_REQUEST: 'REJECT_REQUEST',
  SET_IS_LOADING: 'SET_IS_LOADING',
}

export const myTradesMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
  REMOVE_LIST_ITEM: 'REMOVE_LIST_ITEM',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
}

export const myTradesGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  FETCH_NEXT: 'FETCH_NEXT',
}
