export function genMyTradesState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    fetchNext: null,
  }
}

export default genMyTradesState
